<template>
  <div>
    <img :src="$url_api + 'img/404.webp'" alt="404" />
  </div>
</template>
<script>
export default {
  head() {
    return {
      title: '404 Not Found',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: '404 Not Found',
        },
      ],
    }
  },
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: top;
}
</style>